import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
        <p style={{ textAlign: "justify" }}>
    Hi Everyone, I am <span className="purple">Gedion Daniel</span> from <span className="purple">Italy.</span>
    <br />
    Apart from coding, some other activities that I love to do! 
    <ul>
        <li>🚲 Cycling</li>
        <li>💻 Writing Tech Blogs</li>
        <li>🌍 Travelling</li>
    </ul>
</p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The illiterate of the 21st century will not be those who cannot read and write, but those who cannot learn, unlearn, and relearn."{" "}
          </p>
          <footer className="blockquote-footer">Alvin Toffler</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
