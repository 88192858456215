import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import gediBoss from "../../Assets/blog-images/gedi-boss-pass-saa-exam.jpg";
import camera from "../../Assets/blog-images/camera.webp";
import ses from "../../Assets/blog-images/ses.png";

function BlogPost({ title, date, excerpt, link, image, platform }) {
  return (
    <Col md={6} className="blog-card">
      <div className="blog-card-inner p-4 h-100">
        <div className="blog-image-container mb-3">
          <img 
            src={image || "/api/placeholder/400/200"} 
            alt={title} 
            className="blog-image"
          />
          <span className="platform-badge">{platform}</span>
        </div>
        <h3 className="blog-title mb-3">{title}</h3>
        <p className="blog-date text-muted mb-3">{date}</p>
        <p className="blog-excerpt mb-4">{excerpt}</p>
        <a 
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="read-more-link"
        >
          Read More →
        </a>
      </div>
    </Col>
  );
}

function Blog() {
  const [blogPosts] = useState([
    {
      title: "How I Passed the AWS Certified Solutions Architect Associate Exam 🎯📚",
      date: "Jan 30, 2025",
      excerpt: "My personal journey and study strategy for passing the AWS Solutions Architect Associate certification, including key resources, practice tips, and exam preparation insights.",
      link: "https://www.linkedin.com/pulse/how-i-passed-aws-solutions-architect-associate-exam-gedion-daniel-d28xf/?trackingId=JcIXcZOmAg8TbGwkhAJcSg%3D%3D",
      image: gediBoss,
      platform: "LinkedIn"
    },
    {
      title: "AWS SES with a NestJS Backend to Send Email Verifications",
      date: "Oct 22, 2024",
      excerpt: "Step-by-step guide on integrating AWS Simple Email Service (SES) with a NestJS backend to implement secure email verification system, including setup, configuration, and best practices.",
      link: "https://blog.gediondaniel.dev/aws-ses-with-a-nestjs-backend-to-send-email-verifications",
      image: ses,
      platform: "Hashnode"
    },
    {
      title: "Node.js CLI Tool for Controlling PTZ Cameras via the ONVIF Protocol",
      date: "Aug 13, 2024",
      excerpt: "building a Node.js command-line interface tool to control PTZ (Pan-Tilt-Zoom) cameras using the ONVIF protocol, with practical examples and implementation details.",
      link: "https://blog.gediondaniel.dev/nodejs-cli-tool-for-controlling-ptz-cameras-via-the-onvif-protocol",
      image: camera,
      platform: "Hashnode"
    }
  ]);

  return (
    <Container fluid className="blog-section">
      <Particle />
      <Container>
        <h1 className="blog-heading text-center mb-5">
          My Recent <strong className="purple">Blog Posts</strong>
        </h1>
        <Row className="g-4">
          {blogPosts.map((post, index) => (
            <BlogPost key={index} {...post} />
          ))}
        </Row>
        
        <div className="view-more-section text-center mt-5">
          <p className="view-more-text mb-4">
            Want to explore more?
          </p>
          <div className="view-more-buttons">
            <Button
              href="https://blog.gediondaniel.dev"
              className="view-more-btn hashnode-btn"
            >
              Visit My Hashnode Blog
            </Button>
            <Button
              href="https://www.linkedin.com/in/gedion-daniel-760ba6280/"
              className="view-more-btn linkedin-btn"
            >
              Follow Me on LinkedIn
            </Button>
          </div>
        </div>
      </Container>

      <style jsx>{`
        .blog-section {
          padding: 100px 0;
          background-color: var(--section-background-color);
          color: white;
          position: relative;
        }
        
        .blog-card {
          margin-bottom: 30px;
        }
        
        .blog-card-inner {
          background-color: rgb(2, 2, 108);
          border-radius: 15px;
          transition: all 0.3s ease;
          height: 100%;
          position: relative;
          overflow: hidden;
        }
        
        .blog-card-inner:hover {
          transform: translateY(-7px);
          box-shadow: 0 10px 20px rgba(0,0,0,0.2);
        }

        .blog-image-container {
          position: relative;
          width: 100%;
          height: 200px;
          overflow: hidden;
          border-radius: 10px;
        }

        .blog-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: transform 0.3s ease;
        }

        .blog-card-inner:hover .blog-image {
          transform: scale(1.05);
        }

        .platform-badge {
          position: absolute;
          top: 10px;
          right: 10px;
          background: rgba(199, 112, 240, 0.9);
          color: white;
          padding: 4px 12px;
          border-radius: 20px;
          font-size: 0.8rem;
        }
        
        .blog-title {
          color: white;
          font-size: 1.3rem;
          font-weight: 600;
        }
        
        .blog-excerpt {
          color: rgba(234, 255, 0, 0.6);
          font-size: 1rem;
        }
        
        .read-more-link {
          color: white;
          text-decoration: none;
          font-weight: 500;
          transition: color 0.3s ease;
          display: inline-flex;
          align-items: center;
        }
        
        .read-more-link:hover {
          color: #c770f0;
        }

        .read-more-link:after {
          content: "→";
          margin-left: 5px;
          transition: transform 0.3s ease;
        }

        .read-more-link:hover:after {
          transform: translateX(5px);
        }

        /* View More Section Styles */
        .view-more-section {
          padding: 40px 0 20px;
          position: relative;
          z-index: 2;
        }

        .view-more-text {
          font-size: 1.2rem;
          color: white;
          margin-bottom: 20px;
        }

        .view-more-buttons {
          display: flex;
          justify-content: center;
          gap: 20px;
          flex-wrap: wrap;
        }

        .view-more-btn {
          display: inline-block;
          padding: 12px 24px;
          border-radius: 30px;
          font-weight: 600;
          text-decoration: none;
          transition: all 0.3s ease;
          border: none;
          cursor: pointer;
          font-size: 1rem;
          position: relative;
          z-index: 3;
        }

        .hashnode-btn {
          background-color: #2962ff;
          color: white !important;
        }

        .linkedin-btn {
          background-color: #0077b5;
          color: white !important;
        }

        .view-more-btn:hover {
          transform: translateY(-3px);
          box-shadow: 0 5px 15px rgba(0,0,0,0.3);
          opacity: 0.9;
        }
      `}</style>
    </Container>
  );
}

// Button component to ensure proper styling and clickability
const Button = ({ children, href, className }) => (
  <a
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    className={className}
    style={{
      display: 'inline-block',
      textDecoration: 'none',
      color: 'white',
    }}
  >
    {children}
  </a>
);

export default Blog;